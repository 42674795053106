import * as React from 'react';
import BaseLayout from './BaseLayout';
import { ReactNode } from 'react';
import BackgroundImage from '../BackgroundImage/BackgroundImage';
import classnames from 'classnames';

interface Props {
  title: string;
  imageSrc: string;
  children?: ReactNode;
  headerImageAnchor?: 'left' | 'right';
}

const SubPageLayout = ({ title, imageSrc, children, headerImageAnchor }: Props) => {
  return (
    <BaseLayout title={title}>
      <div className="header-padding">
        <BackgroundImage
          className={classnames('d-none d-md-block', { left: headerImageAnchor === 'left', right: headerImageAnchor === 'right' })}
          style={{ height: 500 }}
          alt={`Header image for ${title}`}
          src={imageSrc}
        />
      </div>

      <main className="pb-5 px-sm-0">
        {children}
      </main>
    </BaseLayout>
  );
};

export default SubPageLayout;
