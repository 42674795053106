import * as React from 'react';
import { Col, Row } from 'reactstrap';

const LostOrStolenCardTile = () => {
  return (
    <Row>
      <Col className="d-flex justify-content-center" xs={2}>
        <i className="pt-2 fas fa-2x fa-credit-card" />
      </Col>
      <Col xs={10}>
        <div>Lost or Stolen Card (After Hours)</div>
        <div>Debit/ATM (800) 383-8000</div>
        <div>Credit Card (800) 325-3678</div>
      </Col>
    </Row>
  );
};

export default LostOrStolenCardTile;
