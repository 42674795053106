// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-borrow-tsx": () => import("./../../../src/pages/borrow.tsx" /* webpackChunkName: "component---src-pages-borrow-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-member-resource-center-tsx": () => import("./../../../src/pages/member-resource-center.tsx" /* webpackChunkName: "component---src-pages-member-resource-center-tsx" */),
  "component---src-pages-mission-tsx": () => import("./../../../src/pages/mission.tsx" /* webpackChunkName: "component---src-pages-mission-tsx" */),
  "component---src-pages-news-info-tsx": () => import("./../../../src/pages/news-info.tsx" /* webpackChunkName: "component---src-pages-news-info-tsx" */),
  "component---src-pages-save-tsx": () => import("./../../../src/pages/save.tsx" /* webpackChunkName: "component---src-pages-save-tsx" */),
  "component---src-pages-services-tsx": () => import("./../../../src/pages/services.tsx" /* webpackChunkName: "component---src-pages-services-tsx" */),
  "component---src-pages-terms-tsx": () => import("./../../../src/pages/terms.tsx" /* webpackChunkName: "component---src-pages-terms-tsx" */)
}

