import * as React from 'react';
import { Col, Row } from 'reactstrap';

const FacebookTile = () => {
  return (
    <Row>
      <Col className="d-flex justify-content-center" xs={2}>
        <i className="pt-2 far fa-2x fa-thumbs-up" />
      </Col>
      <Col xs={10}>
        <div>
          Facebook
        </div>
        <div>
          <a className="text-white" href="https://www.facebook.com/RMECUforU">@RMECUforU</a>
        </div>
      </Col>
    </Row>
  );
};

export default FacebookTile;
