import React from 'react';

interface Props extends Omit<React.HTMLProps<HTMLImageElement>, 'crossOrigin'> {
  src: string;
  alt: string;
}

const CdnImage = ({ src, alt, ...props }: Props) => {
  const cdnEnabled = process.env.NODE_ENV !== 'development';

  return (
    <img {...props} src={cdnEnabled ? new URL(src, 'https://rmecu-19e5f.kxcdn.com').href : src} alt={alt} />
  );
};

export default CdnImage;
