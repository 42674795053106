import * as React from 'react';
import { Col, Row } from 'reactstrap';

const LocationTile = () => {
  return (
    <Row>
      <Col className="d-flex justify-content-center" xs={2}>
        <i className="pt-2 fas fa-2x fa-map-marker-alt" />
      </Col>
      <Col xs={10}>
        <div>Visit</div>
        <div>
          1907 Lathrop Ave.
          Racine, WI  53405
        </div>
      </Col>
    </Row>
  );
};

export default LocationTile;
