import * as React from 'react';
import classnames from 'classnames';

export interface DivExtendableProps extends React.HTMLProps<HTMLDivElement>{
  white?: boolean;
  fill?: boolean;
  childSpacingX?: 1|2|3|4|5;
  childSpacingY?: 1|2|3|4|5;
}

interface Props extends DivExtendableProps {
  children: any;
}

const Div = ({ white, childSpacingX, childSpacingY, fill, className, children, ...rest }: Props) => {
  return (
    <div
      {...rest}
      className={
        classnames(className, {
          'bg-white': white,
          'flex': fill,
          [`child-spacing-x-${childSpacingX}`]: childSpacingX,
          [`child-spacing-y-${childSpacingY}`]: childSpacingY,
        })
      }
    >
      {children}
    </div>
  );
};

export default Div;
