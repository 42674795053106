import React, { useRef, useState } from 'react';
import _ from 'lodash';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import Select from 'react-select';
import { searchLinks } from '../../data/site.json';

interface Props {
  show: boolean;
  hide: () => void;
}

const SearchModal = ({ show, ...props }: Props) => {
  const ref = useRef<any>(null);
  const [ selectedOption, setSelectedOption ] = useState<typeof searchLinks[0]>();
  function handleChange(option: typeof searchLinks[0]) {
    (async () => {
      const { link } = option;
      const isRelative = _.startsWith(link, '/');

      if (isRelative) {
        window.location.href = link;
      } else {
        const win = window.open(link, '_blank');
        if (win) {
          win.focus();
        }
      }

      setSelectedOption(option);
    })();
  }

  return (
    <Modal isOpen={show} toggle={props.hide} zIndex={2001} onOpened={() => ref.current?.focus?.()}>
      <ModalHeader>Search for Resources</ModalHeader>
      <ModalBody>
        <div className="row">
          <div className="col-md-12">
            <Select
              ref={ref}
              value={selectedOption}
              openMenuOnFocus
              placeholder="Search..."
              getOptionLabel={(option) => option.label}
              getOptionValue={(option) => option.link}
              onChange={handleChange as any}
              options={_.sortBy(searchLinks, l => l.label)}
            />
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default SearchModal;
