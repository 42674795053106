import './sub-sections.scss';

import * as React from 'react';
import { ReactElement } from 'react';
import SubSection from './SubSection';

interface Props {
  children: ReactElement<typeof SubSection>[];
}

const SubSections = ({ children }: Props) => {
  return (
    <div className="sub-sections">
      {
        children
      }
    </div>
  );
};

export default SubSections;
