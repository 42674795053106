import * as React from 'react';
import _ from 'lodash';
import { Col, Row, Table } from 'reactstrap';

interface Hours {
  days: string;
  hours: string;
}

interface Props {
  lobbyHours: Hours[];
  driveThruHours: Hours[];
}

const HoursTile = ({ driveThruHours, lobbyHours }: Props) => {
  return (
    <Row>
      <Col className="d-flex justify-content-center" xs={2}>
        <i className="pt-2 far fa-2x fa-clock" />
      </Col>
      <Col xs={10}>
        <div>Hours</div>
        <Row>
          <Col xs={2}>Lobby</Col>
          <Col xs={1}>|</Col>
          <Col>
            <Table borderless size="sm">
              <tbody>
                {
                  _.map(lobbyHours, ({ days, hours }) => (
                    <tr>
                      <td>{days}</td>
                      <td>{hours}</td>
                    </tr>
                  ))
                }
              </tbody>
            </Table>
          </Col>
        </Row>

        <Row>
          <Col xs={2}>Drive Thru</Col>
          <Col xs={1}>|</Col>
          <Col>
            <Table borderless size="sm">
              <tbody>
                {
                  _.map(driveThruHours, ({ days, hours }) => (
                    <tr>
                      <td>{days}</td>
                      <td>{hours}</td>
                    </tr>
                  ))
                }
              </tbody>
            </Table>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default HoursTile;
