import './sub-section.scss';

import * as React from 'react';
import { ReactNode } from 'react';
import { Container } from 'reactstrap';

interface Props {
  anchor: string;
  headerLabel?: ReactNode;
  children?: ReactNode;
}

const SubSection = ({ anchor, headerLabel, children }: Props) => {
  return (
    <div id={anchor} className="sub-section">
      <Container>
        <div className="px-3 px-md-0">
          {headerLabel && (
            <div className="mt-2 heading">
              <h3 className="text-primary">{headerLabel}</h3>
            </div>
          )}
          <div className="width-90 md-width-100 center-col">
            {children}
          </div>
        </div>
      </Container>
    </div>
  );
};

export default SubSection;
