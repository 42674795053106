import React from 'react';
import ReactMarkdown from 'react-markdown';
import ExternalLink from '../ExternalLink/ExternalLink';

const Markdown = ({ source }: { source: string }) => {
  return (
    <div className="markdown">
      <ReactMarkdown
        source={source}
        renderers={{
          link: ExternalLink,
        }}
      />
    </div>
  );
};

export default Markdown;
