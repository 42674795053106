import * as React from 'react';
import { ReactNode } from 'react';
import Helmet from 'react-helmet';
import SiteMap from '../SiteMap/SiteMap';
import { Container } from 'reactstrap';
import AnnouncementOverlay from '../../components/AnnouncementOverlay/AnnouncementOverlay';

interface Props {
  title: string;
  children: ReactNode;
}

const BaseLayout = ({ title, children }: Props) => {
  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>

      <>
        {children}
      </>

      <div className="py-3 bg-secondary">
        <Container>
          <SiteMap />
        </Container>
      </div>

      <AnnouncementOverlay />
    </>
  );
};

export default BaseLayout;
