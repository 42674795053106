import * as React from 'react';
import CallTile from './CallTile';
import LocationTile from './LocationTile';
import HoursTile from './HoursTile';
import RoutingNumberTile from './RoutingNumberTile';
import FacebookTile from './FacebookTile';

interface Hours {
  days: string;
  hours: string;
}

interface Props {
  lobbyHours: Hours[];
  driveThruHours: Hours[];
}

const ContactTiles = ({ lobbyHours, driveThruHours }: Props) => {
  return (
    <>
      <CallTile />
      <LocationTile />
      <HoursTile lobbyHours={lobbyHours} driveThruHours={driveThruHours} />
      <RoutingNumberTile />
      <FacebookTile />
    </>
  );
};

export default ContactTiles;
