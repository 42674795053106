import './background-image.scss';

import React from 'react';
import classnames from 'classnames';

interface Props extends React.HTMLProps<HTMLDivElement> {
  src: string;
}

const BackgroundImage = ({ style, src, className, ...props }: Props) => {
  return (
    <div
      {...props}
      className={classnames('background-image', className)}
      style={{ ...style, backgroundImage: `url('${src}')` }}
    />
  );
};

export default BackgroundImage;
