import * as React from 'react';
import { Col, Row } from 'reactstrap';

const CallTile = () => {
  return (
    <Row>
      <Col className="d-flex justify-content-center" xs={2}>
        <i className="pt-2 fas fa-2x fa-phone-volume" />
      </Col>
      <Col xs={10}>
        <div>Call</div>
        <div>(262) 637-7274</div>
        <div>(866) 763-2848</div>
      </Col>
    </Row>
  );
};

export default CallTile;
