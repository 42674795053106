import * as React from 'react';
import { Col, Row } from 'reactstrap';

const RoutingNumberTile = () => {
  return (
    <Row>
      <Col className="d-flex justify-content-center" xs={2}>
        <i className="pt-2 fas fa-2x fa-university" />
      </Col>
      <Col xs={10}>
        <div>
          Routing Number
        </div>
        <div>
          275981420
        </div>
      </Col>
    </Row>
  );
};

export default RoutingNumberTile;
