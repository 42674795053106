import React, { ReactNode } from 'react';
import { Button } from 'reactstrap';
import classnames from 'classnames';
import { ExternalLink } from '../index';

interface Props {
  icon: string;
  to: string;
  file?: boolean;
  children: ReactNode;
}

const IconButton = ({ icon, to, children, file }: Props) => {
  if(file) {
    return (
      <a target="_blank" rel="noopener noreferrer" href={to} className="p-4 mb-3 mb-md-0 btn btn-outline-primary btn-block">
        <div>
          <i className={classnames('fas fa-4x', icon)} />
        </div>

        <div className="mt-2">
          {children}
        </div>
      </a>
    );
  }

  return (
    <Button tag={ExternalLink} href={to} color="outline-primary" block className="p-4 mb-3 mb-md-0">
      <div>
        <i className={classnames('fas fa-4x', icon)} />
      </div>

      <div className="mt-2">
        {children}
      </div>
    </Button>
  );
};

export default IconButton;
