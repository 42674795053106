import * as React from 'react';
import * as _ from 'lodash';
import { Link } from 'gatsby';

const allowedExternalHosts: { host: string }[] = [ { host: 'bsdcapi.onlinecu.com' }, { host: '@rmecu.org' } ];

const message = 'YOU ARE LEAVING RMECU.ORG \n\nRMECU does not provide, and is not responsible for, the product, service or overall website content available at a third-party site. \n\nRMECU neither endorses the information, content, presentation or accuracy nor makes any warranty, express or implied, regarding any external site. \n\nRMECU privacy policies do not apply to linked websites. You should consult the privacy disclosures on any linked site for further information.';


interface Props extends Omit<React.HTMLProps<HTMLAnchorElement>, 'ref'> {}

const ExternalLink = ({ href, children, ...rest }: Props) => {
  const local = _.startsWith(href, '/');

  const onClick = (e: React.MouseEvent) => {
    e.stopPropagation();

    const isAllowed = _.some(allowedExternalHosts, ({ host: allowedHost }) => {
      return _.includes(_.toLower(href), _.toLower(allowedHost));
    });

    if (!isAllowed && !window.confirm(message)) {
      e.preventDefault();
    }
  };

  if(local && href) {
    return (
      <Link to={href} {...rest} target="_blank" rel="noopener noreferrer">
        {children}
      </Link>
    );
  }

  return (
    <a href={href} {...rest} target="_blank" rel="noopener noreferrer" onClick={onClick}>
      {children}
    </a>
  );
};

export default ExternalLink;
