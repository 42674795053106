import './site-map.scss';
import * as React from 'react';
import { Col, Row } from 'reactstrap';
import { Link } from 'gatsby';
import ContactTiles from '../ContactTiles/ContactTiles';
import generalData from '../../data/general.json';

const SiteMap = () => {
  return (
    <Row className="site-map">
      <Col md={12} lg={8}>
        <Row>
          <Col md={4}>
            <ul className="navbar-nav ml-auto">
              <li className="nav-item">
                <Link to="/borrow" className="nav-link">Borrow</Link>
                <ul className="navbar-nav">
                  <li className="nav-item">
                    <Link to="/borrow/#vehicle-loans" className="nav-link">Vehicle Loans</Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/borrow/#home-loans" className="nav-link">Home Loans</Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/borrow/#home-equity-loans" className="nav-link">Home Equity Loans</Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/borrow/#credit-cards" className="nav-link">Credit Cards</Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/borrow/#personal-loans" className="nav-link">Personal Loans</Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/borrow/#loan-rates" className="nav-link">Loan Rates</Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/borrow/#loan-application" className="nav-link">Loan Application</Link>
                  </li>
                </ul>
              </li>
            </ul>
          </Col>
          <Col md={4}>
            <ul className="navbar-nav ml-auto">
              <li className="nav-item">
                <Link to="/save" className="nav-link">Save</Link>
                <ul className="navbar-nav">
                  <li className="nav-item">
                    <Link to="/save/#checking" className="nav-link">Checking</Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/save/#savings" className="nav-link">Savings</Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/save/#certificates" className="nav-link">Certificates (CDs)</Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/save/#retirement-accounts" className="nav-link">Retirement Accounts (IRAs)</Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/save/#heath-savings-accounts" className="nav-link">Health Savings Accounts (HSAs)</Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/save/#savings-rates" className="nav-link">Savings Rates</Link>
                  </li>
                </ul>
              </li>
            </ul>
          </Col>
          <Col md={4}>
            <ul className="navbar-nav ml-auto">
              <li className="nav-item">
                <Link to="/services" className="nav-link">Services</Link>
                <ul className="navbar-nav">
                  <li className="nav-item">
                    <Link to="/services/#online-banking" className="nav-link">Online Banking</Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/services/#other-services" className="nav-link">Member Services</Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/services/#atm-locator" className="nav-link">Free ATM Locator</Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/services/#shared-branching" className="nav-link">Shared Branching</Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/services/#calculators" className="nav-link">Calculators</Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/services/#order-checks" className="nav-link">Order Checks</Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/services/#report-lost-stolen-card" className="nav-link">Report a Lost/Stolen Card</Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/services/#fraud-identity-theft" className="nav-link">Fraud/Identity Theft</Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/services/#service-fees" className="nav-link">Service Fees</Link>
                  </li>
                </ul>
              </li>
            </ul>
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <ul className="navbar-nav ml-auto">
              <li className="nav-item">
                <Link to="/mission" className="nav-link">Our Mission</Link>
                <ul className="navbar-nav">
                  <li className="nav-item">
                    <Link to="/mission/#our-story" className="nav-link">Our Story</Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/mission/#cu-for-me" className="nav-link"><em>The CU for </em>{' '}<strong>ME</strong> Difference</Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/mission/#how-to-join" className="nav-link">How to Join</Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/mission/#scholarship" className="nav-link">The Longmore Esson Scholarship</Link>
                  </li>
                </ul>
              </li>
            </ul>
          </Col>
          <Col md={4}>
            <ul className="navbar-nav ml-auto">
              <li className="nav-item">
                <Link to="/contact" className="nav-link">Contact</Link>
                <ul className="navbar-nav">
                  <li className="nav-item">
                    <Link to="/borrow/#loan-application" className="nav-link">Apply Now</Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/member-resource-center" className="nav-link">Member Resources</Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/news-info" className="nav-link">News and Info</Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/member-resource-center/#faqs" className="nav-link">FAQs</Link>
                  </li>
                </ul>
              </li>
            </ul>
          </Col>
        </Row>
      </Col>

      <Col className="site-map-info child-spacing-y-2" md={12} lg={4}>
        <ContactTiles driveThruHours={generalData.hours.driveThru} lobbyHours={generalData.hours.lobby} />
      </Col>
    </Row>
  );
};

export default SiteMap;
