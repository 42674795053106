import '../assets/scss/styles.scss';

import * as React from 'react';
import { ReactNode, useEffect, useState } from 'react';
import Helmet from 'react-helmet';

import { Link } from 'gatsby';
import { useLocation } from '@reach/router';
import { Button, Container } from 'reactstrap';
import { ExternalLink, FlexRow, SiteMap } from '../components';
import CdnImage from '../components/CdnImage/CdnImage';
import SearchModal from '../components/SearchModal/SearchModal';

interface Props {
  children: ReactNode;
}

const BaseLayout = ({ children }: Props) => {
  const location = useLocation();
  const [ isSearchShowing, setIsSearchShowing ] = useState(false);
  const [ isSidebarShowing, setIsSidebarShowing ] = useState(false);
  useEffect(() => {
    document.body.classList.add('sidebar-menu');
  }, []);

  useEffect(() => {
    if(isSidebarShowing) {
      document.body.classList.add('overflow-hidden');
    } else {
      document.body.classList.remove('overflow-hidden');
    }
  }, [ isSidebarShowing ]);

  useEffect(() => {
    recalculateNavbar();
  }, [ location.pathname ]);

  useEffect(() => {
    const sidebarMenu = document.getElementsByClassName('sidebar-menu')[0];
    const sideMenu = document.getElementsByClassName('side-menu')[0];

    if(!sideMenu || !sidebarMenu) {
      return;
    }

    if(isSidebarShowing) {
      sidebarMenu.classList.add('active');
      sideMenu.classList.add('side-menu-active');
    } else {
      sidebarMenu.classList.remove('active');
      sideMenu.classList.remove('side-menu-active');
    }
  }, [ isSidebarShowing ]);

  useEffect(() => {
    window.addEventListener('scroll', recalculateNavbar);
    return () => {
      window.removeEventListener('scroll', recalculateNavbar);
    };
  });

  function recalculateNavbar() {
    const doc = document.documentElement;
    const bodyScroll = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    const navbar = document.getElementsByClassName('navbar')[0];

    if(!navbar) {
      return;
    }

    if(bodyScroll > 70){
      navbar.classList.add('nav-scroll');
    }else{
      navbar.classList.remove('nav-scroll');
    }
  }

  function handleSideMenuClick(e: React.MouseEvent<HTMLElement>) {
    const isAnchor = (e.target as any).tagName === 'A';
    if(isAnchor) {
      setIsSidebarShowing(false);
    }
  }

  function closeSidebar() {
    setIsSidebarShowing(false);
  }

  function toggleSidebar() {
    setIsSidebarShowing(!isSidebarShowing);
  }

  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: 'en',
        }}
        defaultTitle="RMECU"
        titleTemplate="%s | RMECU" />

      <>
        <header className="header slider-fade small-height-align">
          <nav className="navbar navbar-expand-lg border-top">
            <div className="container sm-padding-10px-tb sm-padding-15px-lr">
              <Link to="/" className="logo" onClick={() => setIsSidebarShowing(false)}>
                <CdnImage src="/img/logo.png" alt="RMECU Logo" />
              </Link>
              <FlexRow childSpacingX={2}>
                <div className="d-none d-xl-flex align-items-center child-spacing-x-3">
                  <a href="mailto:cu4me@rmecu.org">
                    <i className="far fa-envelope-open" /> cu4me@rmecu.org
                  </a>
                  <a>
                    <i className="fas fa-phone-volume" /> (262) 637-7274
                  </a>
                  <a>
                    <i className="fas fa-university" /> 275981420
                  </a>
                </div>
                <div className="d-flex d-xl-none align-items-center child-spacing-x-3">
                  <a className="btn btn-secondary btn-sm" href="https://bsdc.onlinecu.com/racine">
                    Login
                  </a>
                </div>

                <div className="icons">
                  <a href="#!" className="small fill margin-15px-left" onClick={(e) => {
                    e.preventDefault();
                    setIsSearchShowing(true);
                  }}>
                    <i className="fa fa-search" />
                  </a>

                  <a onClick={toggleSidebar} href="javascript:void(0);" className="sidemenu_btn" id="sidebar_toggle">
                    <i className="fa fa-bars" />
                  </a>

                  <SearchModal show={isSearchShowing} hide={() => setIsSearchShowing(false)} />
                </div>
              </FlexRow>
            </div>
          </nav>
          <div className="side-menu bg-secondary" onClick={handleSideMenuClick}>
            <div className="d-block inner-wrapper align-items-start pt-0">
              <div className="py-3">
                <Link to="/">
                  <CdnImage height={20} src="/img/white-logo.png" alt="RMECU Logo" />
                </Link>

                <span onClick={closeSidebar} className="btn-close" />
              </div>
              <nav className="side-nav width-100">
                <SiteMap />
              </nav>
            </div>
          </div>
          <a onClick={closeSidebar} href="#!" />
        </header>

        <div>
          {children}
        </div>

        <footer className="font-size13 bg-theme text-white">
          <Container className="d-block d-md-none text-center child-spacing-y-2 py-2">
            <span>Federally Insured by NCUA</span>

            <div>
              Equal Housing Opportunity
            </div>

            <div>
              <div>©{new Date().getFullYear()} RMECU</div>

              <div>Powered by Cardinal Compass</div>
            </div>

            <div className="child-spacing-x-1">
              <Link to="/terms" className="text-white">Terms of Use</Link>
              <span>|</span>
              <a className="text-white" target="_blank" rel="noopener noreferrer" href="/privacy-notice.pdf">Privacy Notice</a>
            </div>
          </Container>
          <Container className="d-none d-md-block">
            <FlexRow alignCenter childSpacingX={2} fill justifyBetween className="footer-bottom flex-wrap p-3">
              <FlexRow alignCenter childSpacingX={2}>
                <span>Federally Insured by NCUA</span>

                <div>
                  Equal Housing Opportunity
                </div>

                <span>NMLS# 411638</span>

                <FlexRow alignCenter childSpacingX={2} className="text-white">
                  <span>©{new Date().getFullYear()} RMECU</span>
                  <span>Powered by <ExternalLink className="text-white" href="https://www.cardinalcompass.com/">Cardinal Marketing Group</ExternalLink></span>
                </FlexRow>
              </FlexRow>
              <FlexRow alignCenter childSpacingX={2}>
                <Link to="/terms" className="text-white">Terms of Use</Link>
                <span>|</span>
                <a className="text-white" target="_blank" rel="noopener noreferrer" href="/privacy-notice.pdf">Privacy Notice</a>
              </FlexRow>
            </FlexRow>
          </Container>
        </footer>
      </>
    </>
  );
};

export default BaseLayout;
