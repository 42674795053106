import './main-heading.scss';

import * as React from 'react';
import { ReactNode } from 'react';
import { Markdown } from '../../index';

interface Props {
  label: ReactNode;
  text?: string;
  children?: ReactNode;
}

const MainHeading = ({ label, text, children }: Props) => {
  return (
    <div className="main-heading">
      <div className="container">
        <div className="section-heading">
          <h1 className="mb-0">{label}</h1>
        </div>

        {text && (
          <div className="md-width-100 center-col">
            <p className="text-center">
              <Markdown source={text} />
            </p>
          </div>
        )}

        {children}
      </div>
    </div>
  );
};

export default MainHeading;
